






















































import { Component, Mixins, Ref, Vue } from "vue-property-decorator";
import { Purpose } from "@/graphql/client";
import purposeService from "@/service/purposeService";
import { NotificationState } from "@/store/modules/notification";
import PurposeEditDialog from "@/components/organisms/purpose/PurposeEditDialog.vue";
import PurposeDeleteDialog from "@/components/organisms/purpose/PurposeDeleteDialog.vue";
import MixinFormatter from "@/components/mixins/MixinFormatter.vue";

@Component({
  components: {
    PurposeEditDialog,
    PurposeDeleteDialog,
  },
})
export default class PurposeList extends Mixins(MixinFormatter) {
  @Ref() readonly editDialog!: PurposeEditDialog;
  @Ref() readonly deleteDialog!: PurposeDeleteDialog;
  //---------------------------
  // data
  //---------------------------
  items: Purpose[] = [];
  readonly headers = [
    {
      text: "カテゴリ",
      value: "categoryName",
      width: "20%",
    },
    {
      text: "スラッグ",
      value: "slugName",
      width: "15%",
    },
    {
      text: "名称",
      value: "name",
    },
    {
      text: "表示順",
      value: "displayOrder",
      width: "15%",
    },
    {
      text: "有効/無効",
      value: "isAvailable",
      width: "15%",
      formatter: this.getIsAvailableLabel,
    },
    {
      text: "",
      value: "editAction",
      width: "30px",
      sortable: false,
    },
    {
      text: "",
      value: "deleteAction",
      width: "30px",
      sortable: false,
    },
  ];

  searchKeyword = "";
  loadingDataGrid = false;
  //---------------------------
  // mounted
  //---------------------------
  mounted(): void {
    this.fetchData();
  }

  //---------------------------
  // computed
  //---------------------------
  //---------------------------
  // methods
  //---------------------------
  async fetchData(): Promise<void> {
    this.loadingDataGrid = true;
    purposeService.allPurposes().then((items) => {
      this.items = items;
      this.loadingDataGrid = false;
    });
  }
  /**
   * 用途編集ダイアログ（新規追加）を表示します.
   */
  public openCreateDialog(): void {
    this.editDialog.open(purposeService.defaultPurpose);
  }

  /**
   * 用途編集ダイアログを表示します.
   */
  public openEditDialog(item: Purpose): void {
    this.editDialog.open(Object.assign({}, item));
  }

  /**
   * 用途編集ダイアログの更新成功時.
   */
  public onUpdateSuccess(updated: Purpose): void {
    //一覧にも反映
    const index = this.items.findIndex((el) => {
      return el.guid === updated.guid;
    });
    if (index > -1) {
      this.items.splice(index, 1, updated);
    }

    this.editDialog.close();
    NotificationState.notifySuccess("用途を更新しました！");
  }

  /**
   * 用途編集ダイアログの登録成功時.
   */
  public onCreateSuccess(created: Purpose): void {
    //一覧にも追加
    this.items.unshift(created);

    this.editDialog.close();
    NotificationState.notifySuccess("用途を登録しました！");
  }

  /**
   * 用途削除ダイアログを表示します.
   */
  public openDeleteDialog(item: Purpose): void {
    this.deleteDialog.open(Object.assign({}, item));
  }

  /**
   * 用途削除ダイアログの処理成功時.
   */
  public onDeleteSuccess(deleted: Purpose): void {
    //一覧からも削除
    const newItems = this.items.filter((item) => item.guid !== deleted.guid);
    this.items = newItems;

    this.deleteDialog.close();
    NotificationState.notifySuccess("用途を削除しました！");
  }
}
