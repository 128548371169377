



















































































































































import { Purpose, Option } from "@/graphql/client";
import { Component, Ref, Emit, Mixins } from "vue-property-decorator";
import purposeService from "@/service/purposeService";
import optionService from "@/service/optionService";
import ProgressLinear from "@/components/molecules/ProgressLinear.vue";
import MixinFormatter from "@/components/mixins/MixinFormatter.vue";
import MixinValidator from "@/components/mixins/purpose/MixinPurposeEditDialogValidator.vue";
import moment from "moment";

@Component({
  components: {
    ProgressLinear,
  },
})
export default class PurposeEditDialog extends Mixins(
  MixinFormatter,
  MixinValidator
) {
  @Ref() readonly itemForm!: HTMLFormElement;
  //---------------------------
  // data
  //---------------------------
  isOpen = false;
  isProgressing = false;
  item: Purpose = purposeService.defaultPurpose;
  options: Option[] = [];
  valid = false;
  //---------------------------
  // mounted
  //---------------------------
  //---------------------------
  // computed
  //---------------------------
  get formattedCreatedAt(): string {
    return this.formatDateTime(this.item.createdAt);
  }
  get formattedUpdatedAt(): string {
    return this.formatDateTime(this.item.updatedAt);
  }
  get formattedIsAvailable(): string {
    return this.getIsAvailableLabel(
      this.item.isAvailable ? this.item.isAvailable : false
    );
  }
  //---------------------------
  // methods
  //---------------------------
  async fetchData(item: Purpose): Promise<void> {
    this.item = purposeService.defaultPurpose;
    if (item.guid) {
      const purpose = await purposeService.getPurpose(item.guid);
      if (purpose) {
        this.item = purpose;
      }
    }

    this.options = await optionService.allOptions();
  }

  /**
   * ダイアログを表示します.
   */
  public async open(item: Purpose): Promise<void> {
    await this.fetchData(item);

    this.isProgressing = false;
    this.isOpen = true;
  }

  /**
   * ダイアログを閉じます.
   */
  public close(): void {
    this.$v.$reset();
    this.isProgressing = false;
    this.isOpen = false;
  }

  /**
   * 入力内容を保存します.
   */
  save(): void {
    this.$v.$touch();
    if (!this.$v.$invalid) {
      this.isProgressing = true;
      if (this.item.guid) {
        //update
        purposeService.updatePurpose(this.item).then((result) => {
          this.isProgressing = false;
          this.notifyUpdateSuccess(result as Purpose);
        });
      } else {
        //create
        purposeService.createPurpose(this.item).then((result) => {
          this.isProgressing = false;
          this.notifyCreateSuccess(result as Purpose);
        });
      }
    }
  }

  /**
   * 登録成功
   */
  @Emit("onCreateSuccess")
  public notifyCreateSuccess(created: Purpose): Purpose {
    return created;
  }
  /**
   * 更新成功
   */
  @Emit("onUpdateSuccess")
  public notifyUpdateSuccess(updated: Purpose): Purpose {
    return updated;
  }
  /**
   * 失敗
   */
  @Emit("onError")
  public notifyError(message: string): string {
    return message;
  }
}
