










import SubPageTemplate from "@/components/templates/SubPageTemplate.vue";
import PurposeList from "@/components/organisms/purpose/PurposeList.vue";

export default {
  components: {
    SubPageTemplate,
    PurposeList,
  },
};
