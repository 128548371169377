







































import { Purpose } from "@/graphql/client";
import { Component, Emit, Vue } from "vue-property-decorator";
import purposeService from "@/service/purposeService";
import ProgressLinear from "@/components/molecules/ProgressLinear.vue";

@Component({
  components: {
    ProgressLinear,
  },
})
export default class PurposeDeleteDialog extends Vue {
  //---------------------------
  // data
  //---------------------------
  isOpen = false;
  isProgressing = false;
  item: Purpose = purposeService.defaultPurpose;
  //---------------------------
  // mounted
  //---------------------------
  //---------------------------
  // computed
  //---------------------------
  //---------------------------
  // methods
  //---------------------------
  /**
   * ダイアログを表示します.
   */
  public open(item: Purpose): void {
    this.item = item;

    this.isProgressing = false;
    this.isOpen = true;
  }

  /**
   * ダイアログを閉じます.
   */
  public close(): void {
    this.isProgressing = false;
    this.isOpen = false;
  }

  /**
   * 入力内容を保存します.
   */
  deleteItem(): void {
    this.isProgressing = true;
    //delete
    purposeService.deletePurpose(this.item).then((result) => {
      this.isProgressing = false;
      this.notifySuccess(result as Purpose);
    });
  }

  /**
   * 処理成功
   */
  @Emit("onSuccess")
  public notifySuccess(deleted: Purpose): Purpose {
    return deleted;
  }
}
